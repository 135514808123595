<template>
  <v-overlay :value="getOverlay.value"  z-index="500">
    <v-progress-circular indeterminate color="primary" size="80" class="d-block mx-auto"></v-progress-circular>
    <h5 class="text-h5 mt-2">{{getOverlay.text}}</h5>
  </v-overlay>
</template>

<script>
export default {
  name: 'global-overlay',
  computed:{
    getOverlay(){
      return this.$store.getters.getOverlay
    }
  }
}
</script>
