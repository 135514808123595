import _ from 'lodash';
import moment from 'moment';
moment.locale('es-mx');
const listFormat = new Intl.ListFormat("es");
import axios from 'axios';
axios.defaults.withCredentials = true;
//Version 1.2
/**
 * En esta version se añadieron la opciones de callback personalizados para catchResponses
 * En catch response se añadio la alerta en caso de que no exista e.response
 *  Igual esta la opcion de evento por defecto para catchResponse
 *
 */

export default {
  methods: {
    /**
     * Esta funcion se encarga de atrapar los errores
     * @param {Error} e
     * @param {Object} options
     * {
     *  namespace: 'inputs',
     *  customEvents: {
     *    500: {Callback}
     *    ...
     *  }
     * }
     */
    catchResponse(e, options) {
      if (e.response !== undefined) {
        let namespace = options !== undefined ? options.namespace !== undefined ? options.namespace : 'inputs' : 'inputs';
        let customEvents = options !== undefined ?
          (options.customEvents !== undefined && typeof options.customEvents == 'object' ? options.customEvents : {})
          : {};

        //Si si hay evento personalizado para este codigo de error
        if (e.response.status !== undefined && customEvents[e.response.status] && typeof customEvents[e.response.status] == 'function') {
          customEvents[e.response.status](e);
          return;
        }

        //En caso de que exista errores en el formulario
        let v = this;
        if (e.response.status == 422) {
          let errors = e.response.data.errors;

          if (Array.isArray(errors)) {
            //Si los errores vienen en un array
            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              text: `${errors.join('<br>')}`,
              icon: `error`,
            });
          } else if (typeof errors == 'string') {
            //Si el error es unico
            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              text: `${errors}`,
              icon: `error`,
            });
          } else {
            let aditionalErros = []
            //Si lo errores vienen en un objeto
            _.forEach(errors, function (value, index) {

              //Si hay algun error de errores se concatena en solo un array
              let errors2 = [];
              _.forEach(value, e => {
                if (Array.isArray(e)) {
                  errors2 = errors2.concat(e);
                } else {
                  errors2.push(e);
                }
              });
              value = errors2;

              if (v[namespace]) {
                if (v[namespace][index] !== undefined) {
                  v[namespace][index].errors = value
                } else {
                  const some = _.some(v[namespace], function (value2, key) {
                    if (value2 !== null && value2.key !== undefined && value2.key == index) {
                      v[namespace][key].errors = value;
                      return true;
                    }
                    return false;
                  });
                  if (!some) {
                    aditionalErros = aditionalErros.concat(value)
                  }
                }
              } else {
                aditionalErros = aditionalErros.concat(value)
              }
            });

            this.$swal.fire({
              position: 'top-end',
              title: `Error`,
              html: `<div style="max-height: 30vh; overflow-y: auto;">Hay errores en el formulario. ${aditionalErros.length > 0 ? '<br>' + aditionalErros.join('<br>') : ''}</div>`,
              icon: `error`,
              showConfirmButton: aditionalErros.length > 0,
              timer: aditionalErros.length > 0 ? undefined : 3000,
              backdrop: false,
            });
          }

        } else if (e.response.status == 419) {
          this.$swal.fire({
            title: `Error`,
            text: `Error 419, No te reconocemos, por favor recarga la pagina.`,
            icon: `warning`
          });
        } else if (e.response.status == 401) {
          this.$swal.fire({
            title: `Warning`,
            text: `Error 401, No tienes permiso para acceder a este recurso.`,
            icon: `warning`
          });
        } else {
          if (e.response.status != undefined) {
            let message = e.response.message != undefined ? e.response.message : `Esto no debia de haber pasado, Intenta mas tarde`;

            this.$swal.fire({
              title: `Error`,
              text: `Error(${e.response.status}): ${message}`,
              icon: `error`
            });
          } else {
            //Evento por defecto
            if (e.response != undefined && e.response.status !== undefined && customEvents.default && typeof customEvents.default == 'function') {
              customEvents.default(e);
              return;
            }
            this.$swal.fire({
              title: `Error`,
              text: `Esto no debia de haber pasado, Intenta mas tarde`,
              icon: `error`
            });
          }
        }

      } else {
        //Evento por defecto
        if (e.response != undefined && e.response.status !== undefined && customEvents.default !== undefined && typeof customEvents.default == 'function') {
          customEvents.default(e);
          return;
        }

        this.$swal.fire({
          title: `Error`,
          text: `Esto no debia de haber pasado, Intenta mas tarde`,
          icon: `error`
        });

      }
    },
    resetErrors(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';
      _.forEach(this[namespace], (value, index) => {
        this[namespace][index].errors = [];
      });
    },
    resetInputs(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';

      _.forEach(this[namespace], (value, index) => {
        if (this[namespace][index].default !== undefined) {
          this[namespace][index].value = this[namespace][index].default;
        } else {
          if (this[namespace][index].value !== undefined) {
            this[namespace][index].value = '';
          }
        }

      });
    },
    trimInputsText(namespace) {
      namespace = namespace != undefined ? namespace : 'inputs';

      _.forEach(this[namespace], (value, index) => {
        if (typeof this[namespace][index].value == 'string') {
          this[namespace][index].value = this[namespace][index].value.trim();
        }
      });
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatTime(date) {
      return moment(date).format('HH:mm');
    },
    formatDatetime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    storagePrivateLink(path) {
      return this.$base_api_url + '/private/storage?file=/' + path;
    },
    formatPrice(price) {
      return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price);
    },
    /**
     * Esa funcion nos ayuda a generar una estructura aceptada por los inputs de tipo select
     * de vutify, generando aparte de un pbjeto llave -> valor, un array de objetos
     * @param {Objets} data Un objeto de tipo llave -> valor
    */
    objectToItemsSelect(data) {
      return _.map(data, (valor, key) => {
        return {
          text: valor,
          value: key
        }
      })
    },
    parseJson(text) {
      if (typeof text == 'string') {
        return JSON.parse(text);
      }
      if (text == null || text == undefined) {
        return null;
      }
      return text;
    },
    list(array) {
      return listFormat.format(array);
    },
    showOverlay(text = 'Cargando...', size = 80) {
      const data = { value: true, text: text, size: size }
      this.$store.dispatch("setOverlay", data);
    },
    hiddenOverlay() {
      const data = { value: false, text: '', size: '80' }
      this.$store.dispatch("setOverlay", data);
    },
    uniqid(prefix = "", random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000;
      const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ""}`;
    },
    sanctumToken() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.$base_api_sanctum_url + "/sanctum/csrf-cookie")
          .then(() => {
            resolve()
          })
          .catch((e) => {
            reject(e);
          });
      })
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      }
      else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      // because unescape has been deprecated, replaced with decodeURI
      //return unescape(dc.substring(begin + prefix.length, end));
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    existeXSRF() {
      const cookie = this.getCookie('XSRF-TOKEN');
      return cookie !== null;
    }


  }
}
