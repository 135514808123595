import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./plugins/store";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueSweetalert2 from 'vue-sweetalert2';


Vue.config.productionTip = false;
Vue.prototype.$url_server = process.env.VUE_APP_URL_SERVER
Vue.prototype.$base_api_url = process.env.VUE_APP_BASE_API_URL
Vue.prototype.$base_api_sanctum_url = process.env.VUE_APP_BASE_API_SANCTUM_URL

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueSweetalert2);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
