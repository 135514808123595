import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import overlay from './overlay';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    overlay
  },
})