export default {
  state: () => ({
    globalOverlay: {
      value: false,
      size: 80,
      text: null
    }
  }),
  mutations: {
    set_overlay(state, data) {
      state.globalOverlay.value = data.value
      state.globalOverlay.size = data.size
      state.globalOverlay.text = data.text
    },
  },
  actions: {
    setOverlay({ commit }, data) {
      commit('set_overlay', data);
    },
  },
  getters: {
    getOverlay(state) {
      return state.globalOverlay;
    },
  }
}