import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import store from "../plugins/store"

/* import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout"; */

const Administrador = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Administrador.vue");
const Cedis = () => import(/* webpackChunkName: "pages" */ "@/views/Cedis.vue");
const Clientes = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Clientes.vue");
const Cobranza = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Cobranza.vue");
const Archivos = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Archivos.vue");
const Iniciocedis = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Iniciocedis.vue");
const Iniciocobranza = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Iniciocobranza.vue");
  
const Subirarchivo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Subirarchivo.vue");
const VerArchivos = () =>
  import(/* webpackChunkName: "pages" */ "@/views/VerArchivos.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/login.vue");
const Contraseña = () =>
  import(/* webpackChunkName: "pages" */ "@/views/contraseña.vue");
const Reestablecer = () => import(/* webpackChunkName: "pages" */  "@/views/reestablecer.vue");

const RouterRole = () => import(/* webpackChunkName: "pagers" */ "@/views/RouterRole.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/router",
    name: "Router",
    component: RouterRole,
  },
  {
    path: "/",
    name: "start",
    redirect: "/auth",
    component: AuthCoverLayout,
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: "/login",
    component: AuthCoverLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          auth: false,
        },
      },
      {
        path: "/nueva-contrasena/:token",
        name: "Nueva-contraseña",
        component: Contraseña,
        meta: {
          auth: false,
        },

      },
      {
        path: "/reestablecer-contrasena",
        name: "RestablecerContraseña",
        component: Reestablecer,
        meta: {
          auth: false,
        },

      },
    ],
  },
  {
    path: "/inicio",
    name: "Dashboard",
    redirect: "/inicio/administrador",
    component: DashboardLayout,
    children: [
      {
        path: "administrador",
        name: "Administrador",
        component: Administrador,
        meta: {
          groupName: "Inicio",
        },
      },
      {
        path: "archivos",
        name: "Archivos",
        component: Archivos,
        meta: {
          groupName: "Inicio",
        },
      },
      {
        path: "cedis",
        name: "Iniciocedis",
        component: Iniciocedis,
        meta: {
          groupName: "Inicio",
        },
      },
      {
        path: "cobranza",
        name: "Iniciocobranza",
        component: Iniciocobranza,
        meta: {
          groupName: "Inicio",
        },
      },
    ],
  },
  {
    path: "/usuarios",
    name: "Dashboard",
    redirect: "/usuarios/cedis",
    component: DashboardLayout,
    children: [
      {
        path: "cedis",
        name: "Cedis",
        component: Cedis,
        meta: {
          groupName: "Usuarios",
        },
      },
      {
        path: "clientes",
        name: "Clientes",
        component: Clientes,
        meta: {
          groupName: "Usuarios",
        },
      },
      {
        path: "cobranza",
        name: "Cobranza",
        component: Cobranza,
        meta: {
          groupName: "Usuarios",
        },
      },
    ],
  },
  {
    path: "/archivos",
    name: "Dashboard",
    redirect: "/archivos/archivos",
    component: DashboardLayout,
    children: [
      {
        path: "nuevo-archivo",
        name: "Subirarchivo",
        component: Subirarchivo,
        meta: {
          groupName: "Archivos",
        },
      },
      {
        path: "ver-archivos",
        name: "VerArchivos",
        component: VerArchivos,
        meta: {
          groupName: "Archivos",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {

  if (to.meta.auth === undefined || to.meta.auth !== false) {

    if (store.getters.getUser == null) {
      await store.dispatch('getUser');
      if (store.getters.getUser !== null) {
        if (to.name == 'Login') next({ name: 'Router' });
        else next();
      } else {
        next({ name: 'Login' });
      }
    } else {
      next();
    }

  } else {
    next();
  }

  next();
})

export default router;
